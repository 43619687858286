<template>
    <div>
        <!-- Color Code
#19885E
#1D9C6C
#20AC77
#1DBD81
#16C784
#2ED696 -->
        <div class="top-header blackBG border-bottom">
            <div class="marquee border-top-0 border-bottom">
                <banner-slider></banner-slider>
            </div>
        </div>
        <div class="innerPage-banner heightCalc py-4 whiteBG">
            <div class="container-fluid h-100 calHeight">
                <div class="row h-100">
                    <div class="col-12 col-xl-3">
                        <!-- SearchBar -->
                        <economic-search></economic-search>
                        <div class="mainOverview p-3">
                            <h4 class="green mb-3 text-center">GDP Composition</h4>
                            <!--  <div class="charIQ text-center my-3"><img src="assets/images/map-11.png" alt="map" /></div> -->
                            <div id="indicator_compChart" style="width: 100%; height: 220px"
                                v-show="Object.keys(store.economicGDPData).length > 0"></div>
                            <perfect-scrollbar class="scroll-area scrollGDp">
                                <ul class="listCountry dot mb-0" v-if="chartData.length > 0">
                                    <li v-for="item in chartData">
                                        <a href="#" class="d-flex justify-content-between align-items-center">
                                            <span class="d-flex align-items-center">
                                                {{ item.sector }}</span>
                                            {{ item.size }}
                                        </a>
                                    </li>
                                </ul>
                            </perfect-scrollbar>
                        </div>
                    </div>
                    <div class="col-12 col-xl-9">
                        <div class="mainRightMap">
                            <economic-mainnavbar></economic-mainnavbar>
                            <div class="flexAuto">
                                <economic-map></economic-map>
                                <carousel class="fullOverView" :settings="settings" :breakpoints="breakpoints"
                                    v-if="otherIndicators.length > 0">
                                    <slide v-for="(item, index) in otherIndicators" :key="index">
                                        <a href="javascript:void(0)" class="w-100 d-flex align-items-center">
                                            <span class="flex-shrink-0 me-2 iconRate"><img
                                                    src="assets/images/exchange-rate.svg" alt="Generic image" /></span>
                                            <div class="flex-grow-1">
                                                <span class="iconcolor f-13">{{ item.sector }}</span>
                                                <h3 class="m-0">
                                                    {{ item.size }}
                                                    {{ item.unit == "percent" ? "%" : item.unit }}
                                                </h3>
                                                <!--  <span class="iconcolor f-13 yellowtext">(INR 207.68 trillion)</span> -->
                                            </div>
                                        </a>
                                    </slide>
                                    <template #addons>
                                        <Navigation />
                                        <!-- <pagination /> -->
                                    </template>
                                </carousel>
                            </div>
                        </div>
                    </div>
                    <!-- 
                    <div class="col-12">
                        <bottom-navbar></bottom-navbar>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import economicmainnavbar from "./files/economy_mainnavbar";
import economicmap from "./files/economy_map";
// import bottomnavbar from "./files/bottom_navbar";
import economicsearch from "./files/economy_search";
import bannerslider from "../../components/shared/banner_slider";
import { useMainStore } from "@/store";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import * as index from "@amcharts/amcharts5/index";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { Carousel, Slide, Navigation } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";

export default {
    setup() {
        const store = useMainStore();
        return { store };
    },
    data() {
        return {
            chartData: [],
            otherIndicators: [],
            settings: {
                itemsToShow: 1,
                snapAlign: "center",
            },
            breakpoints: {
                // 700px and up
                767: {
                    itemsToShow: 2,
                    snapAlign: "center",
                },
                1024: {
                    itemsToShow: 3,
                    snapAlign: "start",
                },
                1199: {
                    itemsToShow: 5,
                    snapAlign: "start",
                },
            },
        };
    },
    components: {
        bannerSlider: bannerslider,
        economicMap: economicmap,
        // bottomNavbar:bottomnavbar,
        economicSearch: economicsearch,
        economicMainnavbar: economicmainnavbar,
        Carousel,
        Slide,
        Navigation,
    },
    watch: {
        "store.economicGDPData"() {
            this.drawCompositionChart();
        },
    },
    methods: {
        drawCompositionChart() {
            var sum = 0;
            this.chartData = [];
            this.otherIndicators = [];
            if (Object.keys(this.store.economicGDPData).length > 0) {
                for (let key in this.store.economicGDPData) {
                    for (let innerkey in this.store.economicGDPData[key]) {
                        this.store.economicGDPData[key][innerkey].forEach((item) => {
                            if (item.Category.includes("GDP from")) {
                                sum += parseFloat(item.LatestValue);
                            }
                        });
                        this.store.economicGDPData[key][innerkey].forEach((val) => {
                            if (val.Category.includes("GDP from")) {
                                let percent = parseFloat(
                                    (parseFloat(val.LatestValue) / sum) * 100
                                ).toFixed(2);
                                this.chartData.push({
                                    sector: val.Category,
                                    size: val.LatestValue,
                                    percent: percent,
                                });
                            } else {
                                this.otherIndicators.push({
                                    sector: val.Category,
                                    size: val.LatestValue,
                                    unit: val.Unit,
                                });
                            }
                        });
                    }
                }
            }
            let d = am5.registry.rootElements.find(
                (i) => i.dom && i.dom.id === "indicator_compChart"
            );
            //console.log('gdp',am5.registry)
            d?.dispose();
            var root = am5.Root.new("indicator_compChart");
            root.setThemes([am5themes_Animated.new(root)]);
            var chart = root.container.children.push(
                am5percent.PieChart.new(root, {
                    innerRadius: am5.percent(50),
                    layout: root.verticalLayout,
                })
            );

            var series = chart.series.push(
                am5percent.PieSeries.new(root, {
                    valueField: "size",
                    alignLabels: false,
                    categoryField: "sector",
                })
            );
            series.get("colors").set("colors", [
                am5.color(0x19885E),
                am5.color(0x1D9C6C),
                am5.color(0x20AC77),
                am5.color(0x1DBD81),
                am5.color(0x16C784),
                am5.color(0x2ED696),
            ]);
            series.data.setAll(this.chartData);

            series.labels.template.setAll({
                fontSize: 12,
                text: "{percent}%",
                textType: "circular",
                inside: true,
                radius: 10,
                fill: am5.color(0xffffff),
            });
            series.slices.template.set("toggleKey", "none");
            series.appear(1000, 100);

            // Add label
            // var label = root.tooltipContainer.children.push(am5.Label.new(root, {
            //   x: am5.p50,
            //   y: am5.p50,
            //   centerX: am5.p50,
            //   centerY: am5.p50,
            //   fill: am5.color(0x000000),
            //   fontSize: 50,
            // }));

            // Animate chart data
            // var currentYear = 1995;
            // function getCurrentData() {
            //   var data = chartData[currentYear];
            //   currentYear++;
            //   if (currentYear > 2014)
            //     currentYear = 1995;
            //   return data;
            // }

            //label.set("text", currentYear);
            // function loop() {
            //   var data = getCurrentData();
            //   for(var i = 0; i < data.length; i++) {
            //     series.data.setIndex(i, data[i]);
            //   }
            //   chart.setTimeout( loop, 4000 );
            // }

            // loop();
        },
        callCountryEconomyData(country) {
            let formData = {
                country: [country],
                group: "GDP",
            };
            this.store
                .getCountryEconomyData(formData, true)
                .then((response) => { })
                .catch((err) => console.log(err.message));
        },
    },
    mounted() {
        if (this.$route.query.country) {
            this.callCountryEconomyData(this.$route.query.country);
        } /*else {
      this.$router.replace({
        path: this.$route.path,
        query: { country: "United States" },
      });
      this.callCountryEconomyData("United States");
    }*/
        if (Object.keys(this.store.economicGDPData).length > 0) {
            am5.ready(() => {
                //this.drawCompositionChart();
            });
        }
    },
};
</script>
<style>
.scroll-area {
    position: relative;
    margin: auto;
    width: 100%;
    height: calc(100% - 100px);
}

#indicator_compChart>div {
    display: none;
}

#indicator_compChart>div:last-child {
    display: block !important;
}
</style>
